<template>
  <b-card id="opportunityClosedLost">
    <div class="header">
      <span class="title">{{ title }} </span>
    </div>

    <div class="d-flex body" style="margin: 4px 0">
      <div style="display: flex; flex-direction: column">
        <span>
          Motivo de perda:
          <span class="title">
            {{ getFormatedClosedType(data.closed_type) }}
          </span>
        </span>

        <span v-if="data.closed_description">
          Descrição da perda:
          <span class="title"> {{ data.closed_description }} </span>
        </span>
      </div>
    </div>

    <div class="d-flex body" style="margin: 4px 0">
      Etapa: <b style="margin-left: 4px; color: #000">{{ step }}</b>
    </div>

    <div class="footer d-flex flex-column">
      <div
        class="info"
        v-if="user"
        @click="fromUserModal ? openOpportunity(user.id) : ''"
        :class="fromUserModal ? 'cursor-pointer' : ''"
      >
        {{ fromUserModal ? "Realizado na oportunidade:" : "Realizado por:" }}
        <div class="d-flex flex-row">
          <Avatar
            :hasStatus="false"
            :avatarLetter="$service.getFirstLetter(user.name || 'X')"
            avatarColor="#FFFFFF"
            avatarBackground="#109CF1"
          />

          <div class="d-flex flex-column">
            <span class="name"> {{ user.name }} </span>

            <span class="cod" v-if="user.id"> #{{ user.id }} </span>
          </div>
        </div>
      </div>

      <div
        class="info cursor-pointer"
        style="margin-top: 10px"
        v-if="opportunity"
        @click="openOpportunity(opportunity.id)"
      >
        {{ "Realizado na oportunidade:" }}

        <div class="d-flex flex-row">
          <Avatar
            :hasStatus="false"
            :avatarLetter="$service.getFirstLetter(opportunity.name || 'X')"
            avatarColor="#FFFFFF"
            avatarBackground="#109CF1"
          />

          <div class="d-flex flex-column">
            <span class="name"> {{ opportunity.name }} </span>

            <span class="cod" v-if="opportunity.id"> #{{ opportunity.id }} </span>
          </div>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import Avatar from "../Avatar.vue";

export default {
  name: "OpportunityClosedLost",

  components: {
    BCard,
    Avatar,
  },

  props: {
    title: {
      type: String,
      default: "",
    },

    step: {
      type: String,
      default: "",
    },

    user: {
      type: Object,
      default: null,
    },

    opportunity: {
      default: null,
    },

    data: {
      type: Object,
      default: null,
    },

    fromUserModal: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  computed: {
    reasons_lost() {
      return this.$store.getters["opportunityStore/getLostReasons"];
    },
  },

  created() {
    this.fetchLostReasons();
  },

  methods: {
    fetchLostReasons() {
      if (this.reasons_lost.length === 0) {
        this.$store.dispatch("opportunityStore/fetchLostReasons");
      }
    },

    getFormatedClosedType(closedType) {
      let lostReason;

      this.reasons_lost.forEach((element) => {
        if (element.id == closedType) {
          lostReason = element.name;
        }
      });

      return lostReason;
    },

    openOpportunity(id) {
      this.$store.dispatch("userStore/setOpportunityForHistory", id).then(() => {
        this.$bvModal.show("bv-user-update_opportunity_modal");
      });
    },
  },
};
</script>

<style lang="scss">
.card {
  box-shadow: none;
  border: 1px solid #c4ccd8;
  border-radius: 10px;
  margin: 0 1em 1.5em;
  text-align: left;

  & > .card-body {
    padding: 0rem;

    .header {
      border-bottom: 1px solid #c4ccd8;
      padding: 0.5rem;
      flex-flow: column;
      display: flex;
      justify-content: space-between;

      .title {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #4c5862;
      }
    }

    .footer {
      border-top: 1px solid #c4ccd8;
      padding: 0.5rem;
      flex-flow: row;
      display: flex;
      justify-content: space-between;

      .user {
        flex-flow: row;
        display: flex;

        .info {
          flex-flow: column;
          display: flex;

          .cod {
            color: #afafaf;
            font-size: 0.85em;
          }
        }
      }
    }

    .body {
      padding: 0 0.5em;
      h3 {
        font-weight: 500;
        font-size: 1em;
        line-height: 24px;
        color: #046af3;
        margin: 0px;
      }

      .name {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 19px;
        color: #000000;
        margin: 0px;
      }

      .info-container {
        display: flex;
        flex-flow: row;
      }
    }
  }
}
</style>
