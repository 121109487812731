<template>
  <div>
    <b-modal hide-footer hide-header id="bv-close_deal_lost" class="modal-dialog">
      <div class="modal-close-deal">
        <h2 class="align-text-center mx-2 mb-1" style="text-align: center">
          Oportunidade perdida :(
        </h2>

        <p class="mx-2" style="text-align: center">
          Mas não desanime, com trabalho em equipe e um bom plano de vendas você vai chegar longe!
        </p>

        <validation-observer ref="closeOpportunityValidation">
          <validation-provider
            #default="{ errors }"
            name="Motivo de Perda"
            vid="reasonLost"
            rules="required"
          >
            <b-form @submit.prevent style="padding: 0 20px 20px 20px">
              <small class="text-danger">{{ errors[0] }}</small>

              <v-select
                v-model="opportunity.closed_type"
                :options="reasons_lost"
                :reduce="(option) => option.id"
                id="reasonLost"
                label="name"
                placeholder="Informe o Motivo"
                class="mb-1"
              />

              <b-form-textarea
                placeholder="Descreva mais (Opcional)"
                v-model="opportunity.closed_description"
              />

              <div v-if="isOpenRoom" class="warning-box mt-1">
                <div class="warning-title">Aviso!</div>

                <div>
                  Ao atualizar o status para Perdido você também vai encerrar a conversa, a troca de
                  mensagens entre você e a oportunidade não será mais possível.
                </div>
              </div>

              <span class="buttons">
                <button-component
                  class="button-component"
                  text="Cancelar"
                  style-type="none"
                  v-ripple="'rgba(255, 255, 255, 0.2)'"
                  @click.native="hideModal('bv-close_deal_lost')"
                />

                <button-component
                  class="button-component"
                  style-type="primary"
                  text="Confirmar"
                  v-ripple="'rgba(255, 255, 255, 0.2)'"
                  @click.native="checkLostReasonAndSaveDeal('Lost')"
                />
              </span>
            </b-form>
          </validation-provider>
        </validation-observer>

        <img src="@/assets/images/lost-illustration.svg" class="w-100 m-auto" />
      </div>
    </b-modal>

    <b-modal hide-footer hide-header id="bv-close_deal_won" class="modal-dialog">
      <div class="modal-close-deal">
        <h2 class="mx-2" style="text-align: center">Parabéns pela oportunidade ganha!</h2>

        <p class="mx-2" style="text-align: center">
          "Acreditar é a força que nos permite subir os maiores degraus na escada da vida."
        </p>

        <div v-if="isOpenRoom" class="warning-box">
          <div class="warning-title">Aviso!</div>

          <div>
            Você tem a opção de encerrar a sala de conversa. Ao fazer isso, a troca de mensagens
            entre você e a oportunidade não estará mais disponível.
          </div>

          <b style="color: #000000"> Deseja encerrar a sala de atendimento? </b>

          <div class="d-flex" style="align-items: center; line-height: 0px; gap: 5px">
            <input v-model="opportunity.trigger_room_status" type="checkbox" class="checkbox" />

            Sim, quero fechar.
          </div>
        </div>

        <span class="buttons">
          <button-component
            class="button-component"
            style-type="none"
            text="Cancelar"
            v-ripple="'rgba(255, 255, 255, 0.2)'"
            @click.native="hideModal('bv-close_deal_won')"
          />

          <button-component
            class="button-component"
            style-type="primary"
            text="Confirmar"
            v-ripple="'rgba(255, 255, 255, 0.2)'"
            @click.native="saveDeal('Won')"
          />
        </span>

        <img src="@/assets/images/won-illustration.svg" class="m-auto w-100" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BContainer, BForm, BFormTextarea } from "bootstrap-vue";
import ButtonComponent from "@/views/components/ButtonComponent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";

export default {
  components: {
    BContainer,
    BForm,
    BFormTextarea,
    ButtonComponent,
    ValidationObserver,
    ValidationProvider,
    vSelect,
  },

  props: {
    opportunityProp: {
      required: true,
      default: function () {
        return {};
      },
    },
  },

  data() {
    return {
      opportunity: {},

      newestChat: null,
    };
  },

  computed: {
    reasons_lost() {
      return this.$store.getters["opportunityStore/getLostReasons"];
    },

    isOpenRoom() {
      if (!this.newestChat) {
        return false;
      }

      return this.newestChat.closed_at === undefined || this.newestChat.closed_at === null;
    },
  },

  created() {
    this.fetchLostReasons();
    this.getNewestChatInfo();
  },

  watch: {
    opportunityProp: {
      handler() {
        this.getNewestChatInfo();
      },

      deep: true,
    },
  },

  methods: {
    hideModal(modal) {
      this.$bvModal.hide(modal);
      this.$emit("refresh");
    },

    fetchLostReasons() {
      if (this.reasons_lost.length === 0) {
        this.$store.dispatch("opportunityStore/fetchLostReasons");
      }
    },

    getNewestChatInfo() {
      if (!this.opportunityProp.dialogs?.length) {
        return;
      }

      this.newestChat = this.opportunityProp.dialogs[0];
    },

    checkLostReasonAndSaveDeal(type) {
      const formRefs = this.$refs.closeOpportunityValidation;

      formRefs.validate().then((success) => {
        if (success) {
          if (!this.opportunity.closed_type) {
            return;
          }

          const event = {
            opportunity: this.opportunity,
            state: type,
          };

          this.$emit("saveDeal", event);
        }
      });
    },

    saveDeal(type) {
      const event = {
        opportunity: this.opportunity,
        state: type,
      };

      this.$emit("saveDeal", event);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-close-deal {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 25px 10px 15px 10px;
  color: #4c5862;
  overflow: scroll;

  h2 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #000000;
  }

  .warning-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    padding: 10px 1rem;
    gap: 10px;
    border-radius: 6px;
    background: #f5f6f8;

    .warning-title {
      font-size: 20px;
      font-weight: 500;
      line-height: 26px;
      color: #000000;
    }

    .checkbox {
      height: 20px;
      width: 20px;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    margin-top: 1em;

    button {
      min-width: 100px;
      border-radius: 6px;
      padding: 13px 0;
      text-align: center;
      margin: 0 5px !important;
    }

    button:last-child {
      margin-right: 0px;
    }
  }

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    margin: 4px 0;
    border-radius: 13px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 13px;
    border: 1px solid transparent;
    background-clip: content-box;
    box-shadow: inset 0 0 0 10px #c8c8c8;
  }
}
</style>
