<template>
  <div class="floating-messages">
    <div class="d-flex w-100" style="justify-content: flex-end; padding-right: 1rem">
      <i @click="closeFloatingMessages" class="icon-close-circle-outline" />
    </div>

    <div class="gray-detail" />

    <div class="box-title">Respostas Rápidas</div>

    <div v-if="filteredQuickMessages.length && !hasRequestQuickAnswers" class="quick-messages-box">
      <div class="quick-message">
        <div class="command">/Atalho</div>

        <div class="message">Mensagem</div>
      </div>

      <div
        v-for="(quickMessage, index) in filteredQuickMessages"
        @click="selectQuickMessage(quickMessage, lastWord)"
        :key="index"
        class="quick-message"
      >
        <div class="command">{{ quickMessage.shortcut }}</div>

        <div class="message">{{ quickMessage.content }}</div>
      </div>
    </div>

    <div v-else-if="hasRequestQuickAnswers">
      <b-spinner />
    </div>

    <div v-else class="no-messages-found">Nenhuma mensagem rápida encontrada.</div>
  </div>
</template>

<script>
import { BSpinner } from "bootstrap-vue";

export default {
  components: {
    BSpinner,
  },

  props: {
    message: {
      type: String,
      default: "",
    },

    bufferedMessages: {
      type: Array,
      default: null,
    },

    opportunity: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      quickMessages: [],

      hasRequestQuickAnswers: false,
    };
  },

  computed: {
    user() {
      return this.$store.getters["app/getUserData"];
    },

    variablesDictionary() {
      return {
        atendente: this.user?.name,
        campanha: this.opportunity?.campaign?.name,
        cliente: this.opportunity?.contact?.name,
        cidade: this.opportunity?.contact?.city_name,
        produto: this.opportunity?.product?.name,
        valor_produto: this.opportunity?.product?.price,
      };
    },

    lastWord() {
      let lastWord;

      if (this.message) {
        lastWord = this.message.split(" ");

        lastWord = lastWord[lastWord.length - 1];
      }

      return lastWord;
    },

    filteredQuickMessages() {
      const filteredMessages = [];

      const sanitizedLastWord = this.lastWord?.replace(/\//g, "");

      this.quickMessagesList.forEach((element) => {
        if (element.content?.toLowerCase().includes(sanitizedLastWord?.toLowerCase())) {
          filteredMessages.push(element);
        }
      });

      return filteredMessages;
    },

    quickMessagesList() {
      return this.bufferedMessages?.length ? this.bufferedMessages : this.quickMessages;
    },
  },

  created() {
    if (!this.bufferedMessages) this.getQuickAnswers();
  },

  methods: {
    emit(event, params) {
      this.$emit(event, params);
    },

    closeFloatingMessages() {
      this.emit("close");
    },

    replacePlaceholders(str, variables) {
      return str.replace(/{(\w+)}/g, (match, p1) => {
        return variables[p1] !== undefined ? variables[p1] : "";
      });
    },

    selectQuickMessage(quickMessage, lastWord) {
      const message = this.replacePlaceholders(quickMessage.content, this.variablesDictionary);

      const messageInfo = {
        message: message,
        lastWord: lastWord,
      };

      this.emit("quickMessageSelected", messageInfo);
    },

    getQuickAnswers() {
      this.hasRequestQuickAnswers = true;

      const queryParams = [{ query: "limit", param: 500 }];

      this.$store
        .dispatch("admin/fetchQuickAnswers", this.$service.formatQueryParam(queryParams))
        .then((response) => {
          this.quickMessages = response.data;

          if (this.quickMessages.length) {
            this.emit("quickMessagesLoaded", this.quickMessages);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.hasRequestQuickAnswers = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.floating-messages {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 1000;
  bottom: calc(100% + 1px);
  padding-top: 1rem;
  border-radius: 20px 20px 0px 0px;
  border: 1px solid #e5e5e5;
  box-shadow: -5px -5px 16px 0px #0000001a;
  background: #ffffff;

  .icon-close-circle-outline {
    font-size: 18px;
    color: #14223b;
    cursor: pointer;
  }

  .icon-close-circle-outline:hover {
    color: #ff0000 !important;
  }

  .gray-detail {
    height: 5px;
    width: 100%;
    max-width: 135px;
    margin-bottom: 20px;
    border-radius: 20px;
    opacity: 0.5;
    background: #c4ccd8;
  }

  .box-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.20000000298023224px;
    text-align: center;
    margin-bottom: 15px;
    color: #000000;
  }

  .quick-messages-box {
    display: flex;
    flex-direction: column;
    max-height: 350px;
    width: 100%;
    overflow-y: scroll;

    .quick-message {
      display: flex;
      align-items: center;
      padding: 0.75rem;
      border-bottom: solid 1px #e5e5e5;
      cursor: pointer;

      .command,
      .message {
        font-size: 13px;
        letter-spacing: 0.01em;
        text-align: left;
      }

      .command {
        white-space: nowrap;
        text-overflow: ellipsis;
        line-height: 21.45px;
        width: calc(18% + 10px);
        min-width: calc(18% + 10px);
        padding-right: 10px;
        color: #a39f9f;
        overflow: hidden;
      }

      .message {
        line-height: 19.5px;
        color: #4c5862;
      }
    }

    .quick-message:first-child {
      .command {
        color: #000000 !important;
      }

      .message {
        color: #000000 !important;
      }
    }

    .quick-message:last-child {
      border-bottom: none;
    }

    .quick-message:first-child:hover {
      background: #ffffff;
    }

    .quick-message:hover {
      background: #e5e5e5;
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 13px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 13px;
      border: 1px solid transparent;
      background-clip: content-box;
      box-shadow: inset 0 0 0 10px #c8c8c8;
    }
  }

  .no-messages-found {
    text-align: left;
    width: 100%;
    padding: 0 1rem 1.2rem 1rem;
  }
}

@media (max-width: 800px) {
  .floating-messages {
    width: 100vw;
  }
}
</style>
