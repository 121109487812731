/* eslint-disable no-empty-pattern */
import Vue from "vue";
import { instance } from "@/config/index.js";

const initialState = () => ({
  taskTypes: [],

  endedTypes: [],
});

const getters = {
  getActions(state) {
    return state.taskTypes;
  },

  getEndedTypes(state) {
    return state.endedTypes;
  },
};

const mutations = {
  reset_state_CalendarStore(state) {
    Object.assign(state, initialState());
  },
};

const actions = {
  save({}, task) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    // const taskId = 1
    const postUrl = `/workspaces/${workspaceId}/tasks`;

    if (task.id) {
      // Update
      return new Promise((resolve, reject) => {
        instance()
          .put(`${postUrl}/${task.id}`, task)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    }

    // Insert
    return new Promise((resolve, reject) => {
      instance()
        .post(postUrl, task)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  update({}, task) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const postUrl = `/workspaces/${workspaceId}/tasks/${task.id}`;

    return new Promise((resolve, reject) => {
      instance()
        .put(postUrl, task)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  delete({}, task) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const postUrl = `/workspaces/${workspaceId}/tasks/${task.id}`;

    return new Promise((resolve, reject) => {
      instance()
        .delete(postUrl)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  fetchTasks({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/tasks${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  fetchOneTask({}, taskId) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/tasks/${taskId}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  endTask({}, task) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const postUrl = `/workspaces/${workspaceId}/tasks/${task.id}/end`;

    return new Promise((resolve, reject) => {
      instance()
        .put(postUrl, task)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  fetchTaskTypes({ state }) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/task-types?limit=500`)
        .then((response) => {
          state.taskTypes = response.data;

          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },

  fetchEndedTypes({ state }) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/interaction-types?limit=500`)
        .then((response) => {
          state.endedTypes = response.data;

          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
};

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  getters,
  actions,
};
