import platforms from "./platforms.json";

const initialState = () => ({
  platform: {
    id: 1000,
    name: "CRM",
    short_name: "crm",
    subtitle: "Aumente a sua produtividade ao máximo!",
    url: "",

    spinner_variant: "dark",

    favicon: "/favicon-skillpro.ico",

    trade_mark: "IM Labs",

    has_reset: false,
    has_street_generation: false,

    planning: null,

    logos: {
      default_logo_light: "logo-horizontal-light.svg",
      short_logo_light: "logo-light.svg",

      default_logo: "logo-horizontal.svg",
      short_logo: "logo.svg",
    },

    images: {
      login_background_desktop: "background-login-desktop.webp",
      login_background_mobile: "background.webp",
    },

    palette: {
      primary: "#B3152A",
      secondary: "#ffffff",
      navcolor: "#B3152A",
    },

    changeButton: false,

    terms: {
      won_term: "Ganho",
      lost_term: "Perdido",
      product_term: "um produto",
      price_term: "Valor de venda",
      concluded_term: "Matriculado",
    },

    preset_values: {
      source_type: "Offline",
    },

    main_collaborator: "salesRep",

    dashboard: {
      second_tab: "Atendimento",

      first_tab_title: {
        opportunities_status_chart: "Resumo do Funil",
        opportunities_lost_chart: "Oportunidades perdidas",
        stages_chart: "Funil de vendas",
        source_type_chart: "Origem",
        campaign_id_chart: "Campanhas",
        media_charts: "Mídias",
      },

      second_tab_title: {
        funnel_general: "Funil de Atendimento",
      },
    },

    integrated_enroll: null,

    responsibleCheck: false,

    gradient: {},
  },
});

const getters = {
  getPlatform(state) {
    return state.platform;
  },
};

const actions = {
  setPlatform({ state }) {
    const allPlatforms = Object.values(platforms);
    const url = window.location.host;

    allPlatforms[0].forEach((element) => {
      if (element.url == url) {
        state.platform = element;
      }
    });
  },
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
};
